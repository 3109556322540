import alert from "@/services/alert";
import { MQTT_USERNAME, MQTT_PASSWORD, ROUTING_KEY } from "@/helpers/helper";
import { SERVICE_URL } from "@/service_urls";

const getDefaultState = () => {
  return {
    getNotificationAlerts: [],
    getNotificationEmails: [],
    getNotification: [],
    getShortNotify: false,
    getShowAlert: false,
    client: null,
    getCommunicationChannel: [],
    getCommunicationChannelDetails: [],
  };
};

const state = getDefaultState();

const getters = {
  getNotificationAlerts: (state) => state.getNotificationAlerts,
  getNotificationEmails: (state) => state.getNotificationEmails,
  getNotification: (state) => state.getNotification,
  getShortNotify: (state) => state.getShortNotify,
  getShowAlert: (state) => state.getShowAlert,
  client: (state) => state.client,
  getCommunicationChannel: (state) => state.getCommunicationChannel,
  getCommunicationChannelDetails: (state) =>
    state.getCommunicationChannelDetails,
};

const mutations = {
  ["SET_NOTIFICATION_ALERT"](state, payload) {
    state.getNotificationAlerts = payload;
    for (let i = 0; i < payload.length; i++) {
      if (payload[i].alert_type_id == 6) {
        state.getNotificationEmails.push(payload[i]);
      }
    }
  },
  ["SET_NOTIFICATION"](state, data) {
    state.getNotification = data;
  },
  ["SET_SHORT_NOTIFY"](state, data) {
    state.getShortNotify = data;
  },
  ["SET_SHOW_ALERT"](state, data) {
    state.getShowAlert = data;
  },
  ["SET_CLIENT"](state, data) {
    state.client = data;
  },
  ["SET_COMMUNICATION_CHANNEL"](state, data) {
    state.getCommunicationChannel = data;
  },
  ["SET_COMMUNICATION_CHANNEL_DETAILS"](state, data) {
    state.getCommunicationChannelDetails = data;
  },
};

const actions = {
  fetchNotificationAlerts: ({ commit, getters, dispatch }) => {
    const { getUserId } = getters;
    return alert
      .getNotificationAlerts(getUserId)
      .then(async (res) => {
        let { data } = res;
        commit("SET_NOTIFICATION_ALERT", data.reverse());
      })
      .catch((err) => {
        console.log("Error in fetching notification alerts", err);
        return err;
      });
  },

  clearNotificationAlerts: ({ commit, getters, dispatch }, payload) => {
    for (let i = 0; i < state.getNotificationAlerts.length; i++) {
      for (let j = 0; j < payload.alerts_to.length; j++) {
        if (payload.alerts_to[j] == state.getNotificationAlerts[i].alert_id) {
          state.getNotificationAlerts.splice(i, 1);
        }
      }
    }
    commit("SET_NOTIFICATION_ALERT", state.getNotificationAlerts);
    return alert
      .clearNotificationAlerts(payload)
      .then((res) => {
        let { data } = res;
        dispatch("fetchNotificationAlerts");
      })
      .catch((err) => {
        console.log("Error in clear notification alerts", err);
        return err;
      });
  },

  clearAllNotificationAlerts: ({ commit, getters, dispatch }, payload) => {
    return alert
      .clearNotificationAlerts(payload)
      .then((res) => {
        let { data } = res;
        dispatch("fetchNotificationAlerts");
        return data;
      })
      .catch((err) => {
        console.log("Error in clear notification alerts", err);
        return err;
      });
  },

  fetchOnTimeNotificationAlerts: async ({ commit, getters, dispatch }) => {
    const { getUserId, getNotificationAlerts } = getters;
    var wsbroker = SERVICE_URL; // mqtt websocket enabled broker
    var wsport = window.location.protocol == "https:" ? 15676 : 15675; // 15676 for secure connections || 15675 for non-secure connections
    var client = new Paho.MQTT.Client(
      wsbroker,
      Number(wsport),
      "/ws",
      getUserId.toString() + parseInt(Math.random() * 100, 10)
    );

    if (client.isConnected()) return;

    commit("SET_CLIENT", client);
    client.onConnectionLost = function (responseObject) {
      commit("SET_CLIENT", null);
      console.log("CONNECTION LOST - " + responseObject.errorMessage);
    };

    client.onMessageArrived = async function (message) {
      let message_ = JSON.parse(message.payloadString);

      commit(
        "SET_NOTIFICATION_ALERT",
        state.getNotificationAlerts.concat(message_)
      );
      commit("SET_NOTIFICATION", message_);
      commit("SET_SHORT_NOTIFY", true);
      await new Promise((resolve) =>
        setTimeout(function reset() {
          commit("SET_SHORT_NOTIFY", false);
        }, 10000)
      );
    };

    var options = {
      timeout: 3,
      keepAliveInterval: 30,
      userName: MQTT_USERNAME,
      password: MQTT_PASSWORD,
      onSuccess: function () {
        client.subscribe(`${ROUTING_KEY}/${getUserId}`, {
          qos: 1,
        });
      },
    };
    if (window.location.protocol == "https:") {
      options.useSSL = true;
    }
    await client.connect(options);
  },

  closeConnection: ({ getters, commit }) => {
    const { client } = getters;
    try {
      if (client && client.isConnected()) {
        client.disconnect();
      }
    } catch (err) {
      commit("SET_CLIENT", null);
    }
  },

  // communication channel
  fetchCommunicationChannel: ({ commit, getters, dispatch }) => {
    alert
      .getCommunicationChannel()
      .then((res) => {
        const { data } = res;
        commit("SET_COMMUNICATION_CHANNEL", data);
      })
      .catch((err) => {
        console.log("Error in communication channel", err);
      });
  },

  //communication channel details
  fetchCommunicationChannelDetails: ({ commit, getters, dispatch }) => {
    const { getUserId, getCustomerId, getOrgIDFromAccessToken } = getters;
    let user_id = getUserId;
    let customer_uid = getCustomerId;
    let organisation_id = getOrgIDFromAccessToken;

    alert
      .getCommunicationChannelDetails(user_id, customer_uid, organisation_id)
      .then((res) => {
        const { data } = res;
        commit("SET_COMMUNICATION_CHANNEL_DETAILS", data);
      })
      .catch((err) => {
        console.log("Error in communication channel details", err);
      });
  },

  createCommunicationChannelDetails: (
    { commit, getters, dispatch },
    payload
  ) => {
    const { getUserId, getCustomerId, getOrgIDFromAccessToken } = getters;
    let finalPayload = {
      ...payload,
      customer_uid: getCustomerId,
      organisation_id: getOrgIDFromAccessToken,
      user_id: getUserId,
    };
    alert
      .createCommunicationChannelDetails(finalPayload)
      .then((res) => {
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Channel Updated successfully",
        });
      })
      .catch((err) => {
        console.log("Error in communication channel details", err);
      });
  },

  updateCommunicationChannelDetails: (
    { commit, getters, dispatch },
    { detail_id, payload }
  ) => {
    const { getUserId, getCustomerId, getOrgIDFromAccessToken } = getters;
    let finalPayload = {
      ...payload,
      customer_uid: getCustomerId,
      organisation_id: getOrgIDFromAccessToken,
      user_id: getUserId,
    };
    alert
      .updateCommunicationChannelDetails(detail_id, finalPayload)
      .then((res) => {
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Channel Updated successfully",
        });
      })
      .catch((err) => {
        console.log("Error in communication channel details", err);
      });
  },
};

export default { state, getters, mutations, actions };
