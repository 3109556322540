import { getFilterQueryStringWithoutArray } from "@/helpers/helper.js";
import auditHistory from '@/services/auditHistory';

const state = {
    isFetchingAuditHistory: false,
    auditHistory: [],
    auditHistoryPagination: {
        limit: 20,
        skip: 0,
        noMoreData: false
    },
};

const getters = {
    isFetchingAuditHistory: state => state.isFetchingAuditHistory,
    getAuditHistory: state => state.auditHistory,
    getAuditHistoryPagination: state => state.auditHistoryPagination,
};

const actions = {
    fetchAuditHistory({ dispatch, commit, getters }, payload) {
        const { getAuditHistoryPagination, getAuditHistory } = getters
        let { customer_uid, serviceUrl, pagination, ...rest } = payload;
        let { candidate_uid, job_id } = payload;
        commit("IS_FETCHING_AUDIT_HISTORY", true)
        serviceUrl = "fetchAudit" + serviceUrl.charAt(0).toUpperCase() + serviceUrl.slice(1);
        if (!pagination) {
            commit("RESET_AUDIT_HISTORY");
            commit("SET_AUDIT_HISTORY_PAGINATION", {
                skip: 0,
                noMoreData: false,
            });
        }
        let skip = pagination ? getAuditHistoryPagination.skip : 0;
        let limit = getAuditHistoryPagination.limit;
        let query = getFilterQueryStringWithoutArray({ skip: skip, limit: limit, ...rest })
        return auditHistory[serviceUrl]({ customer_uid, candidate_uid, job_id, query })
            .then(res => {
                const { data } = res;
                if (data.length < limit) {
                    commit("SET_AUDIT_HISTORY_PAGINATION", {
                        noMoreData: true
                    });
                } else {
                    commit("SET_AUDIT_HISTORY_PAGINATION", {
                        skip: skip + limit,
                        noMoreData: false
                    });
                }
                if (pagination) {
                    commit("SET_AUDIT_HISTORY", getAuditHistory.concat(data));
                } else {
                    commit("SET_AUDIT_HISTORY", data);
                }
            })
            .catch(err => {
                console.log("Error while fetching job audit history", err)
            })
            .finally(res => { commit("IS_FETCHING_AUDIT_HISTORY", false); return res; })
    },
    fetchAuditStatusChange({ dispatch }, payload) {
        let query = getFilterQueryStringWithoutArray({ ...payload, skip: 0, limit: 300 })
        return auditHistory.fetchAuditStatusChange({ query })
            .then(res => {
                return res;
            })
            .catch(err => {
                dispatch("showToast", { class: 'bg-danger text-white', message: 'Error while fetching details' })
                console.log("Error while fetching job audit history", err)
                return error
            })
    }
};

const mutations = {
    ["IS_FETCHING_AUDIT_HISTORY"](state, payload) {
        state.isFetchingAuditHistory = payload
    },
    ["SET_AUDIT_HISTORY"](state, payload) {
        state.auditHistory = payload;
    },
    ["RESET_AUDIT_HISTORY"](state) {
        state.auditHistory = []
    },
    ["SET_AUDIT_HISTORY_PAGINATION"](state, payload) {
        state.auditHistoryPagination = {
            ...state.auditHistoryPagination,
            ...payload
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
