import openai from "../services/ai";
import { getFilterQueryStringWithoutArray } from "@/helpers/helper.js";

const getDefaultState = () => {
  return {
    partnersPages: [],
    partnersPagesWithOrg: [],
    generatePartnerPage: [],
  };
};

const state = getDefaultState();

const getters = {
  partnersProfilePages: (state) => state.partnersPages,
  partnersPagesWithOrg: state => state.partnersPagesWithOrg,
  generatedPartnerRes: (state)  => state.generatePartnerPage?.length ? state.generatePartnerPage[0]: null,
};

const mutations = {
  ["SET_PARTNERS_PAGES"](state, data) {
    state.partnersPages = data;
  },
  ["RESET_PARTNERS_PAGES"](state) {
    state.partnersPages = [];
  },
  ["SET_GENERATED_PARTNER_RES"](state, data) {
    state.generatePartnerPage = data;
  },
  ["SET_PARTNERS_PAGES_WITH_ORG"](state, data) {
    state.partnersPagesWithOrg = data;
  },
  ["RESET_PARTNERS_PAGES_WITH_ORG"](state) {
    state.partnersPagesWithOrg = [];
  }
};

const actions = {
  generateTemplate: ({ commit, dispatch }, data) => {
    const {
      name,
      description,
      file: { file, fileName },
      organisation_id,
    } = data;
    const message = `Create a profile page for ${name}. Get the content details for the each section from given document. If content is not available for particular section, you generate your own content with more relevant. The generate html should contain all sections. A short Description for this profile is ${description}.`;
    const formData = new FormData();
    formData.append("file", file, fileName);
    formData.append("message", message);
    return openai
      .generatePartnerPage(formData, organisation_id)
      .then((res) => {
        dispatch("showToast", {
          message: "Profile Page Generation Started...",
          class: "bg-success text-white",
        });
        return res;
      })
      .catch((err) => {
        const message = "Error in Template Creation";
        dispatch("showToast", {
          message,
          class: "bg-danger text-white",
        });
        console.log(message, err);
        return err;
      });
  },
  createPartnerPage: ({ commit, dispatch }, payload) => {
    const {
      organisation_id,
      file: { file, fileName },
    } = payload;
    const formData = new FormData();
    formData.append("file", file, fileName);
    dispatch("showLoader");
    return openai
      .createPartnerPage({ organisation_id, payload: formData })
      .then((res) => {
        dispatch("hideLoader");
        dispatch("showToast", {
          message: "Profile Page Created Successfully",
          class: "bg-success text-white",
        });
        return res;
      })
      .catch((err) => {
        const message = "Error in Profile Page Creation";
        dispatch("hideLoading");
        dispatch("showToast", {
          message,
          class: "bg-danger text-white",
        });
        console.log(message, err);
        return err;
      });
  },
  getPartnersPages: ({ commit, dispatch }, payload) => {
    commit("RESET_PARTNERS_PAGES");
    const query = getFilterQueryStringWithoutArray(payload);
    return openai
      .getPartnerPage(query)
      .then((res) => {
        const { data } = res;
        commit("SET_PARTNERS_PAGES", data);
        return res;
      })
      .catch((err) => {
        const message = "Error in Getting Profile Page";
        dispatch("showToast", {
          message,
          class: "bg-danger text-white",
        });
        console.log(message, err);
        return err;
      });
  },
  getPartnersPagesWithOrg: ({ commit, dispatch }) => {
    commit("RESET_PARTNERS_PAGES_WITH_ORG");
    return openai
      .getPartnerPageWithOrg()
      .then((res) => {
        const { data } = res;
        commit("SET_PARTNERS_PAGES_WITH_ORG", data);
        return res;
      })
      .catch((err) => {
        const message = "Error in Getting Profile Page";
        dispatch("showToast", {
          message,
          class: "bg-danger text-white",
        });
        console.log(message, err);
        return err;
      });
  },
  getHTML: ({ commit, dispatch, getters }, url) => {
    return openai
      .getHTML(url)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  },
  getGenerateResponse: ({ commit, dispatch, getters }, organisation_id) => {
    const queryPayload = {
      all_rows: true,
      fetch_row_count: false,
      order_by: "-partners_page_id",
      limit: 1,
      organisation_id,
    };
    const queryString = getFilterQueryStringWithoutArray(queryPayload);

    return openai
      .getGenerateResponse(queryString)
      .then((res) => {
        const data = res.data;
        const finalData = data?.map((v) => ({
          ...v,
          assistant_message: JSON.parse(v.assistant_message),
        }));
        commit("SET_GENERATED_PARTNER_RES", finalData);
        return finalData;
      })
      .catch((err) => {
        const message = "Error in Getting Generation Status";
        dispatch("showToast", {
          message,
          class: "bg-danger text-white",
        });
        console.log(message, err);
        return err;
      });
  },
};

export default { state, getters, mutations, actions };
